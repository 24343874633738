import { useState, useEffect, useRef } from 'react';
import useApi from 'hooks/use-api';
import { usePortalAppContext } from 'context/portal-app-context';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { gridInterfaces } from 'interfaces';

const useList = (serverDatasource: gridInterfaces.IServerDatasource, getSortState: () => { [key: string]: string | undefined }) => {
  const options = { cachePolicy: 'no-cache', headers: { 'Content-Type': 'application/json' } };
  const listApi = useApi(serverDatasource.url, options);
  const { enqueueAlert }: any  = usePortalAppContext();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const [fetchingCount, setFetchingCount] = useState(0);
  const searchCriteria = useRef<gridInterfaces.IServerDatasource['reqBody']>({});
  const queryStringItems = useRef<gridInterfaces.IServerDatasource['queryParams']>('');

  const setSearchCriteria = (search: gridInterfaces.IServerDatasource['reqBody'], queryString: gridInterfaces.IServerDatasource['queryParams']) => {
      searchCriteria.current = search;
      queryStringItems.current = queryString;
  };

  useEffect(() => {
    if(listApi.error && typeof enqueueAlert === 'function') {
      enqueueAlert({ 
          alertType: 'modal', 
          title: 'Error', 
          messageType: 'error', 
          message: 'An error occurred while processing your request.  Please try again later.',
          message2: undefined, continueCaption: undefined, continueAction: undefined, cancelCaption: undefined, 
          cancelAction: undefined, transitionDuration: undefined 
      });
    }
  }, [listApi.error]);

  const getRows = async (params: IServerSideGetRowsParams) => {
    const { column, direction } = getSortState();

    try {
        listApi.abort();
        
        let pageSize, startPage;
        if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
          pageSize =  params.request.endRow - params.request.startRow;
          startPage = (params.request.startRow / pageSize) + 1;
        }

        const searchItems = searchCriteria.current && Object.keys(searchCriteria.current).length > 0 ? searchCriteria.current : serverDatasource.reqBody;
        const defaultPaginationParams = {
          page: startPage,
          pageSize,
          sortBy: serverDatasource.sortMap[column || 'default'],
          descendingSort: direction === 'desc',
        }
        const paginationParams = serverDatasource.transformPagination ? serverDatasource.transformPagination(defaultPaginationParams) : defaultPaginationParams;
        const searchParams = serverDatasource.reqBody
          ? {
              ...paginationParams,
              ...searchItems,
            }
          : undefined;

        const queryParams = serverDatasource.queryParams
          ? `?${queryStringItems.current ? queryStringItems.current : serverDatasource.queryParams}&page=${startPage}&pageSize=${pageSize}` 
          : undefined;

        setFetchingCount((prevCount) => prevCount + 1);
        if (serverDatasource.reqType === 'post') {
          await listApi.post(queryParams, searchParams);
        } else {
          await listApi.get(queryParams);
        }
        setFetchingCount((prevCount) => prevCount - 1);

        if (listApi.response.ok && listApi.response.status !== 204) {
            if (!serverDatasource.hasOwnProperty('transformResponse') && !listApi.response.data.hasOwnProperty('content')) {
              throw new Error("List source couldn't be found. Please ensure that response has 'content' which has 'Results' or define the 'transformResponse'");
            }
            const responsePayload = serverDatasource.transformResponse ? serverDatasource.transformResponse(listApi.response.data) : listApi.response.data.content;
            const returnRows = serverDatasource.transformRow ? serverDatasource.transformRow(responsePayload.results) : responsePayload.results;
            setTotalRowCount(responsePayload.totalCount);
            setCurrentPageCount(returnRows.length);
            if (params && params.successCallback) params.successCallback(returnRows, responsePayload.totalCount);
            return returnRows;
        } else {
            setTotalRowCount(0);
            setCurrentPageCount(0);
            return [];
        }  
    } catch(err) {
      console.log(err);
    }
  };

  return {
    getRows,
    setSearchCriteria,
    totalRowCount,
    currentPageCount,
    isLoading: fetchingCount > 0
  }
};

export default useList;