import { useState, useEffect } from 'react';
import { usePortalAppContext } from 'context/portal-app-context';
import useApi from 'hooks/use-api';
import useRoles from "hooks/use-roles";
import useDataHierarchy from "hooks/use-hierarchy";
import useLocale from './use-locale';
import { v4 as createUuid} from 'uuid';

const useUser = (context) => {
  const portalContext = usePortalAppContext();
  const {
    config,
    sessionUser,
    getMyProfile,
    setMyProfile,
    brandListEnabled,
    featureFlags = {},
  } = context || portalContext;
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { accountsRequestAccessStatusEnabled } = featureFlags;
  const newUserUrl = config.API.userUrl2;
  const newUserApi = useApi(newUserUrl, options);
  const newUserElasticApi = useApi(newUserUrl, { cachePolicy: 'no-cache', headers: { 'Content-Type': 'application/json', 'source': 'ELASTIC' }});
  const cloneUserApi = useApi(`${newUserUrl}/user/clone`); 
  const publicKeyApi = useApi(newUserUrl?.replace('userservicenew/api/v1', 'user/api/v2'));
  const putUserApi = useApi(newUserUrl, {
    ...options, headers: {
      'Content-Type': 'application/json',
      'TID': createUuid() + 'EDIT_USER_REQUEST'
    }
  });

  const deleteUserApi = useApi(newUserUrl, {
    ...options, headers: {
      'Content-Type': 'application/json',
      'TID': createUuid() + 'DELETE_USER_REQUEST'
    }
  });

  const { getHierarchyRowsFromUserHierarchies, getPortfolios } = useDataHierarchy(context);
  const {getRoleDisplayName, getLocalizedName } = useRoles(context);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { translateToString } = useLocale();
  
  const getRoleNameOfUser = (user) => {
    let roleGuid = null;
    let roleObject = null;
    let appRole = null;
    let roleNameKey = null;
    try {
      appRole = user
        .userApplicationAssignments
        .results
        .find(a => a.assignedApplication.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS')
        .assignedApplication
        .applicationRoleAssociations[0];
      roleObject = appRole.role;
      roleGuid = roleObject.roleGuid;
      roleNameKey = roleObject.name;
    } catch(err) {
      console.log(err); // no accounts app role assignment found
    }
    const roleName = roleGuid ? getRoleDisplayName(roleGuid) : '';
    const localizedDisplayName = roleNameKey ? getLocalizedName(roleNameKey) : ''
    return {
      roleName,
      roleObject: {
        label: localizedDisplayName,
        nameKey: roleNameKey,
        roleGuid: roleGuid,
        roleId: roleObject.roleId,
        roleType: roleObject.roleType,
        value: roleObject.roleId,
        permissions: appRole.permissions,
      },
    };
  };

  const getLastLoginDateOfUser = (user) => {
    let lastLoginDateText = '';
    let lastLoginDate = null;
    if (user.lastLoginDate) {
      lastLoginDate = new Date(user.lastLoginDate);
      const dateText = lastLoginDate.toLocaleDateString('en-US', 
      {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit'
      });
      const timeText = lastLoginDate.toLocaleTimeString('en-US',
      {
        hour: '2-digit',
        minute: '2-digit'
      });
      lastLoginDateText = `${dateText} ${timeText}`;
    };
    return { lastLoginDate, lastLoginDateText };
  };

  const getUser = async (url, filterDuplicateDagEntries = false, errorResponse = false, source) => {
    const getUserApi = source === 'ELASTIC' ? newUserElasticApi : newUserApi;
    try {
      setIsLoading(true);
      await getUserApi.get(url);
      if (getUserApi.response.ok) {
        setIsLoading(false);
        const user = getUserApi.response.data.content;  
        let hierarchyResults = user.userHierarchyAssignments.results;
        if (user.userHierarchyAssignments.totalCount > 50) {
          const rowsToFetch = user.userHierarchyAssignments.totalCount < 500 ? user.userHierarchyAssignments.totalCount : 500;
          const pagesToFetch = Math.ceil(rowsToFetch / 50); 
          for (let i = 2; i <= pagesToFetch; i++) {
            setIsLoading(true);
            await newUserApi.get(`/hierarchy/assignments/${user.userId}?includelnactive=false&hydrateHierarchy=true&page=${i}&pageSize=50`);
            if (newUserApi.response.ok) {
              setIsLoading(false);
              const newHierarchyResults = newUserApi.response.data.content.results;
              hierarchyResults = hierarchyResults.concat(newHierarchyResults);
            }
          }
        }

        const portfolios = await getPortfolios(hierarchyResults);
        const filteDuplicateDags = hierarchyResults.filter(m => m?.accessType === 'DAG').filter((item, index, objects) => {
          if (index === 0 || item?.hierarchyId !== objects[index - 1]?.hierarchyId) {
              return item;
          } 
        })

        portfolios?.forEach((p, idx) => {
          const apiRows = filterDuplicateDagEntries ? hierarchyResults.filter(m => m?.accessType === 'ORGANIC').concat(filteDuplicateDags) : hierarchyResults;
          const rowKeyStart = idx > 0 ? apiRows.filter(r => r?.hierarchyDescendent?.hierarchyConfig?.affiliation === portfolios[idx - 1].affiliation)?.length : 0;
          p.dataAccess = getHierarchyRowsFromUserHierarchies(apiRows, p, false, rowKeyStart);
        });

        const { lastLoginDate, lastLoginDateText } = getLastLoginDateOfUser(user);
        const {roleName, roleObject} = getRoleNameOfUser(user);
        const applicationAssignments = user.userApplicationAssignments.results.filter(r => r.assignedApplication.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS');
        const accountsAppAssignment = applicationAssignments[0];
        const dataAccess = portfolios.map(p => p.dataAccess).flat();
        const dismissedApplicationsValue = user.userTags?.find((tag) => tag.tagId === 'APPLICATION_ALERT_DISMISSED_FOREVER')?.value;
        const dismissedApplications = dismissedApplicationsValue ? JSON.parse(dismissedApplicationsValue).applicationIds : [];
        const resultUser = {
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          locale: user.locale,
          timeFormat: user.timeFormat,
          dateFormat: user.dateFormat,
          brands: user.brands,
          fullName: `${user.firstName} ${user.lastName}`,
          createdDate: new Date(user.createdDateTime),
          createdDateText: new Date(user.createdDateTime).toLocaleDateString('en-US', {'year': 'numeric', 'day': '2-digit', 'month': '2-digit'}),
          lastLoginDate,
          lastLoginDateText,
          role: roleName,
          roleObject: roleObject,
          accountsAppAssignment: {
            userApplicationAssignmentId: accountsAppAssignment.userApplicationAssignmentId,
            applicationId: accountsAppAssignment.applicationId,
            status: accountsAppAssignment.status,
            applicationPlanIds: accountsAppAssignment.applicationPlanIds,
          },
          userApplicationAssignments: user.userApplicationAssignments,
          portfolios,
          dataAccess,
          hasFullPortfolioAssigned: dataAccess.some(x => x.nodes[0]?.selectedChild?.label === 'All'),
          rawDataAccessFromApi: hierarchyResults,
          hasMidsExcludedFromLoggedInUser: user.userHierarchyAssignments.hasExcludedStuff,
          status: user.status,
          dismissedApplications,
        };
        return errorResponse ? { user: resultUser, ok: true } : resultUser;  
      } else {
        setIsLoading(false);
        return errorResponse ? { error: getUserApi.response.data, ok: false } : null;
      }
    }
    catch(err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getUserById = async (userId, errorResponse = false) => {
    const url = `${userId}?hydrateApplications=true&hydrateHierarchies=true&includeInactive=true&hierarchyPageSize=50`;
    const user = await getUser(url, true, errorResponse, 'ELASTIC');
    return user;
  };

  const getAuthenticatedSessionUser = async ({ forceReload = false} = {}) => {
    const myProfile = !forceReload && getMyProfile(false);

    if (myProfile) {
      return myProfile;
    } else {
      const url = '/profile?includeInactive=true&hydrateApplications=true&hydrateHierarchies=true&hierarchyPageSize=50';
      const user = await getUser(url, undefined, undefined, 'ELASTIC');
      setMyProfile(user);
      return user;
      
        
    }
  };

  // check if logged in user has direct access to any hierarchyID in a given array of hierarchies that constitute a lineage
  const hasDirectHierarchyAccess = (myProfile, lineageHierarchies) => {
    if (!myProfile || !myProfile.dataAccess) return false;
    const result = myProfile.dataAccess.some(row => row && row?.selectedHierarchy?.label === 'All') || lineageHierarchies.some(searchHierarchy => myProfile.dataAccess.some(row => row && row.selectedHierarchy?.hierarchyId == searchHierarchy && row.selectedMids.length === 0));
    return result;
  };

  const getUserByEmail = async (email) => {
    try {
      await newUserApi.get(`email/${email}`);
      const result = userListApi.response.data.content;
      return result;
    }
    catch(err) {
      console.log(err);
    }
  };

  const getUserByEmailDataAccess = async (email) => {
    try {
      const user = await getUser(`email/${email}?includeInactive=true&includeDeleted=false&hydrateApplications=true&applicationPage=1&applicationPageSize=25&hydrateHierarchies=true&hydrateHierarchyTags=false&getMerchants=false&hierarchyPage=1&hierarchyPageSize=50&hierarchyDescendentPage=1&hierarchyDescendentPageSize=50`);
      return user;
    }
    catch(err) {
      console.log(err);
    }
  };

  const changeActivationStatus = async (userId, newStatus) => {
    setIsSaving(true);
    const resp = await newUserApi.patch(`/${userId}/status`, { userId, status: newStatus });
    setIsSaving(false);
    return resp;
  };

  const deleteUser = async (userId, ticketId) => {
    setIsSaving(true);
    const resp = await deleteUserApi.del(`/${userId}?ticketNumber=${ticketId}`, { userId, ticketId });
    setIsSaving(false);
    if (resp.status < 200 || resp.status > 204) {
      throw new Error(e)
    }
  };

  const activateUser = async (userId) => {
    return await changeActivationStatus(userId, 'ACTIVE');
  };

  const deactivateUser = async (userId) => {
    return await changeActivationStatus(userId, 'DEACTIVATED');
  };
  const declineAccess = async (userId) => {
    return await changeActivationStatus(userId, 'REJECTED');
  };

  const lockUser = async (userId) => {
    return await changeActivationStatus(userId, 'LOCKED');
  };

  const updateMyProfile = async(originalUserData, {userId, firstName, lastName, locale, dateFormat, timeFormat}) => {
    setIsSaving(true);
    const resp = await newUserApi.patch('/profile', {
      firstName,
      lastName,
      locale,
      dateFormat,
      timeFormat,
      brands: originalUserData.brands,
    });
    const updatedProfile = await getAuthenticatedSessionUser({forceReload: true});
    setIsSaving(false);
    return resp;
  };

  const registerMid = async ({merchantNumber, ddaOrTaxId}) => {
    setIsSaving(true);
    let response = {};
    try {
      const loggedInUser = await getAuthenticatedSessionUser();
      await newUserApi.post(`/selfregistration/${loggedInUser.userId}/mid`, { merchantNumber, dda: ddaOrTaxId });
    }
    catch(e) {
      response.error = e;
    }
    response = newUserApi.response;
    setIsSaving(false);
    return response;
  };

  const requestAccess = async ({merchantNumber, email}) => {
    setIsSaving(true);
    let response = {};
    try {
      await newUserApi.post(`user/requestdataaccess`, { merchantNumber, email });
    }
    catch(e) {
      response.error = e;
    }
    response = newUserApi.response;
    setIsSaving(false);
    return response;
  };

  const updateUser = async (originalUserData, {
    userId,
    firstName,
    lastName,
    roleId,
    dataChanges,
    brands,
    selectedDags,
    optionalPermissionIds,
    updatedApplications,
    status
  }, hasfullPortfolioAccess) => {
    let resp = { ok: true };
    setIsSaving(true);
  
    const updateBrands = brandListEnabled && (
      (originalUserData.brands.length !== brands?.length ||
        originalUserData.brands.filter((b) => brands.indexOf(b) === -1).length)
    );
  
    const updatedUser = {
      userId,
      firstName,
      lastName,
      ...(brandListEnabled && { brands })
    };
  
    const appAssignmentId = originalUserData.accountsAppAssignment.userApplicationAssignmentId;
  
    const newHierarchies = dataChanges?.map(hierarchyId => ({
      userId,
      hierarchyId,
      accessType: 'ORGANIC',
      status: 'ACTIVE',
    })).filter(m => m).concat(selectedDags?.map(hierarchyId => ({
      userId,
      hierarchyId,
      accessType: 'DAG',
      status: 'ACTIVE',
    })).filter(m => m));
  
    const newApps = updatedApplications?.new?.map(app => ({
      userId,
      applicationId: app.applicationId,
      enabled: true,
      roleId: app.roleId,
      roleGuid: app.roleGuid,
      status: "ACTIVE",
      optionalPermissionIds: app.optionalPermissionIds,
      applicationPlanIds: app.applicationPlanIds,
    }));
  
    const delApps = updatedApplications?.deleted?.map(app => app.userApplicationAssignmentId);
  
    const updatedApps = updatedApplications?.updated?.map(app => ({
      userApplicationAssignmentId: app.userApplicationAssignmentId,
      userId,
      applicationId: app.applicationId,
      enabled: true,
      roleId: app.roleId,
      roleGuid: app.roleGuid,
      status: "ACTIVE",
      optionalPermissionIds: app.optionalPermissionIds,
      applicationPlanIds: app.applicationPlanIds,
    }));
  
    
    if (accountsRequestAccessStatusEnabled && status === "APPROVAL_PENDING") {
      const combinedPayload = {
        userId,
        firstName,
        lastName,
        updateUserHierarchyAssignmentPayloads: newHierarchies,
        updateUserApplicationAssignmentPayloads: {
          newApps,
          delApps,
          updatedApps
        }
      };
      resp = await newUserApi.put(`/users/${userId}`, combinedPayload);
    } else {
      if (originalUserData.firstName !== firstName || originalUserData.lastName !== lastName || updateBrands) {
        resp = await newUserApi.patch(`/${userId}`, updatedUser);
      }

      if (!resp.errorCode && originalUserData.roleId !== roleId) {
        resp = await newUserApi.put(`/application/assignments/users/${userId}/${appAssignmentId}`, {
          userApplicationAssignmentId: appAssignmentId,
          userId,
          applicationId: originalUserData.accountsAppAssignment.applicationId,
          enabled: true,
          roleId,
          status: "ACTIVE",
          applicationPlanIds: originalUserData.accountsAppAssignment.applicationPlanIds,
          optionalPermissionIds
        });
      }
      if (!resp.errorCode && (dataChanges || selectedDags)) {
        resp = await putUserApi.put(`/${userId}/hierarchy-assignments`, newHierarchies);
      }

      if (!resp.errorCode && newApps?.length) {
        resp = await newUserApi.post(`/application/assignments/batch/users/${userId}`, newApps);
      }

      if (!resp.errorCode && delApps?.length) {
        resp = await newUserApi.del(`/application/assignments/batch/users/${userId}?userApplicationAssignmentId=${delApps}`);
      }

      if (!resp.errorCode && updatedApps?.length) {
        resp = await newUserApi.put(`/application/assignments/batch/users/${userId}`, updatedApps);
      }
    }

    setIsSaving(false);
    return resp;
  };

  const createUser = async({
    firstName,
    lastName,
    email,
    sendSetupEmail,
    roleId,
    hierarchies,
    brands,
    selectDAG,
    locale = "en-US",
    optionalPermissionIds=[],
    createUserApplicationAssignmentPayloads,
  }) => {
    const newUser = {
      firstName,
      lastName,
      email,
      sendSetupEmail,
      status:"ACTIVE",
      locale,
      userTags:[],
      systemTags:[],
      createUserHierarchyAssignmentPayloads: hierarchies.map(h => ({
        hierarchyId: h,
        accessType: "ORGANIC",
        status: "ACTIVE",
      })).concat(
        selectDAG.map(h => ({
          hierarchyId: h,
          accessType: "DAG",
          status: "ACTIVE",
        }))),
      createUserApplicationAssignmentPayloads,
    };
    if (brandListEnabled) {
      newUser.brands = brands;
    }
    return await newUserApi.post(newUser);
  };

  const cloneUser = async({
    firstName,
    lastName,
    email,
    sourceBrands,
    sourceEmail,
    sendSetupEmail,
    cloneScheduledReports,
  }) => {
    const newUser = {
      firstName,
      lastName,
      email,
      sourceBrands:[],
      sourceEmail,
      sendSetupEmail,
      cloneScheduledReports,
    };
    if (brandListEnabled) {
      newUser.sourceBrands = sourceBrands;
    }
    console.log('newuser',newUser,cloneUserApi);

    return await cloneUserApi.post(newUser);
  };

  const getPublicKey = async () => {
    try {
      const publicKey = await publicKeyApi.get(`encryption/publickey`);
      return publicKey;
    } catch (err) {
      return onError(err);
    }
  };

  const applicationBulkAssignment = async ({
    applicationId,
    roleId,
    roleGuid,
    optionalPermissionIds,
    applicationPlanIds,
  }) => {
    try {
      const resp = await newUserApi.post(`application/assignments/users`, {
        applicationId,
        enabled: true,
        roleId,
        roleGuid,
        status: "ACTIVE",
        optionalPermissionIds,
        applicationPlanIds,
      });
      return resp;
    } catch (err) {
      return onError(err);
    }
  };

  return {
    authenticatedUser: sessionUser,
    getUserById,
    getUserByEmail,
    getAuthenticatedSessionUser,
    hasDirectHierarchyAccess,
    getUserByEmailDataAccess,
    activateUser,
    deactivateUser,
    declineAccess,
    lockUser,
    deleteUser,
    createUser,
    cloneUser,
    updateUser,
    updateMyProfile,
    registerMid,
    requestAccess,
    isSaving,
    isLoading,
    getPublicKey,
    applicationBulkAssignment,
  };
};

export default useUser;
